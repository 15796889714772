<template>
    <!-- 禁飞区管理首页 -->
    <div class="container normal mission-center-box">
        <div class="operation-bar">
            <!-- 三级路由侧边栏 -->
            <div class="detail-tabs" v-if="thirdRouterViewer">
                <div v-for="item in thirdMenu" :key="item.path" 
                    :class="$route.name === item.code ? 'detail-tabs-item detail-tabs-item--active active-back' 
                        : 'detail-tabs-item'" 
                    @click="changeActive(item)">
                    <p class="detail-tabs-item--top">
                        <img class="icon" alt="" 
                            :src="$route.name === item.code? `/img/project/${item.code}.png`: `/img/project/${item.code}.png`" 
                        />
                    </p>
                    <p class="detail-tabs-item--bottom">{{item.name}}</p>
                </div>
            </div>
            <router-view class="third-routing" v-if="thirdRouterViewer" />
        </div>
        <CusiumMap ref="noFlyCusiumMap" v-show="thirdRouterViewer" class="mission-center-lastItem" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CusiumMap from "@/components/cesium/index";

export default {
    name: 'no-fly-zone',
    components: {CusiumMap},
    computed: {
        ...mapGetters(['menuAll']),
        // 三级菜单
        thirdMenu() {
            for (var i = 0; i < this.menuAll.length; i++) {
                const item = this.menuAll[i];
                if (item.code === 'mission-center') {
                    if (item.children && Array.isArray(item.children)) {
                        for (let j = 0; j < item.children.length; j++) {
                            const element = item.children[j];
                            if (element.code === 'no-fly-zone') {
                                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                                    return element.children;
                                }
                            }
                        }
                    }
                }
            }
            return [];
        },
        thirdRouterViewer() {
            let secondLevel = this.$route.matched[1];
            return secondLevel.name === 'no-fly-zone';
        }
    },
    methods: {
        changeActive(node) {
            this.$router.push({path: node.path});
        }
    }
}
</script>

<style lang="scss" scoped>
.operation-transition-leave-active {
  transition: opacity 0.2s;
}
.operation-transition-enter-active {
  transition: opacity 2.5s;
}
.operation-transition-enter,
.operation-transition-leave-to {
  opacity: 0;
}

.mission-center-box {
    width: 100%;
    height: 100%;
    display: flex;
    .operation-bar {
        height: 100%;
        display: flex;
        background-color: rgba(30, 34, 42, 1);
        .detail-tabs {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 58px;
            border-right: 1px solid #030303;
            border-top: 1px solid #030303;
            &-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                cursor: pointer;
                color: #76787d;
                font-size: 12px;
                position: relative;
                .icon {
                    height: 20px;
                }
                &--active {
                    font-weight: bold;
                    color: #3a76ff;
                    &::after {
                        position: absolute;
                        content: "";
                        width: 52px;
                        height: 6px;
                        background-image: url("/img/project/icon-active.png");
                        background-size: cover;
                        bottom: 0px;
                    }
                }
                &.active-back {
                    background: #030303;
                }
            }
            .detail-tabs-item--bottom {
                text-align: center;
            }
        }
        .third-routing {
            width: 290px;
            border-top: 1px solid #030303;
            height: 100%;
        }
    }
    .mission-center-lastItem{
        flex-grow: 1;
        overflow: hidden;
    }
}
</style>

<style lang="scss">
.no-fly_cont-dj {
    display: flex;
    flex-direction: column;
    .no-fly_top {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #030303;
        padding: 15px 10px;
        .title {
            display: flex;
            align-items: center;
            .title-text {
                display: flex;
                align-items: center;
                flex: 1;
                font-size: 14px;
                color: #FFFFFF;
                .img-sm {
                    width: 13px;
                    height: 13px;
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
            .icon-upload {
                width: 20px;
                height: 16px;
                margin-left: 8px;
                cursor: pointer;
            }
            .icon-download {
                width: 19px;
                height: 14px;
                margin-left: 8px;
                cursor: pointer;
            }
            .el-icon-circle-plus-outline {
                color: #FFFFFF;
                margin-left: 8px;
                font-size: 17px;
                cursor: pointer;
            }
        }
        .search {
            margin-top: 15px;
            .el-input__inner {
                background-color: #030303;
                border-color: #535353;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 14px;
            }
            .el-cascader {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .no-fly_list {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-bottom: 5px;
        overflow-y: auto;
        .no-data {
            text-align: center;
            margin-top: 10px;
            color: #76787D;
            font-size: 14px;
            .el-icon-folder-delete {
                font-size: 25px;
            }
        }
        .no-fly_list-item {
            display: flex;
            align-items: center;
            margin-top: 7px;
            padding: 10px;
            cursor: pointer;
            &.temporary {
                flex-direction: column;
                align-items: initial;
            }
            &:first-child {
                margin-top: 0;
            }
            &.active {
                background-color: #3573FF33;
            }
            .item-label {
                flex: 1;
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                color: #FFFFFF;
            }
            .item-icon {
                margin-left: 5px;
                cursor: pointer;
                .el-icon-aim {
                    font-size: 17px;
                    color: #FFFFFF;
                    margin-left: 7px;
                }
                .icon-download {
                    width: 19px;
                    height: 14px;
                    margin-left: 7px;
                }
                .el-icon-delete {
                    font-size: 17px;
                    color: #FFFFFF;
                    margin-left: 7px;
                }
            }
            .list-item-top {
                display: flex;
                align-items: center;
                .item-label {
                    flex: 1;
                    font-size: 14px;
                    font-weight: 700;
                    font-style: normal;
                    color: #FFFFFF;
                }
                .item-icon {
                    margin-left: 5px;
                    cursor: pointer;
                    .el-icon-aim {
                        font-size: 17px;
                        color: #FFFFFF;
                        margin: 0 7px;
                    }
                    .icon-download {
                        width: 19px;
                        height: 14px;
                    }
                    .el-icon-delete {
                        font-size: 17px;
                        color: #FFFFFF;
                        margin-left: 7px;
                    }
                }
            }
            .list-item-bottom {
                margin-top: 8px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
.draw-tooltip {
    .draw-list {
        font-size: 14px;
        color: #FFFFFF;
        padding-top: 10px;
        cursor: pointer;
        &:first-child {
            padding-top: 0;
        }
        &:hover {
            color: #409EE8;
        }
    }
}
.sm-tooltip {
    font-size: 13px;
}
</style>